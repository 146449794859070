








import { Component, Vue } from "vue-property-decorator";
import ListaReservas from "@/components/Reserva/ListaReservas.vue";

@Component({
  components: {
    ListaReservas
  }
})
export default class ListaReservasView extends Vue {
  private mounted() {
    document.title = "Reservas - Municipalidad de Arica";
    var canonicalLink = document.createElement("link");
    // Establece los atributos del enlace

    canonicalLink.rel = "canonical";
    canonicalLink.href = "https://muniarica.cl/municipio/reserva";
    document.head.appendChild(canonicalLink);
  }
}
